<template>
  <div id="privacy">
    <section
      class="page-header"
      style="background-image: url('/imgs/privacy_policy.png')"
    >
      <div class="caption">
        <i class="isologo"></i>
        <h1>{{ $lang("title") }}</h1>
      </div>
    </section>

    <section class="py-6 text-medium lh-5">
      <div class="container" v-html="$lang('content')"></div>
    </section>
  </div>
</template>
